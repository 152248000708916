import { combineReducers } from 'redux';
import { routerReducer } from '@dealroadshow/connected-next-router';
import companies from '@/users/application/actions/companies/reducer';
import user from '@/users/application/actions/reducer';
import roadshow from '@/dealroadshow/application/actions/reducer';
// Temporary added as dependency for Tenant Context that is used in Schedule Demo.
// Will be removed once Redux is removed from Tenant Context dependencies. Now it is used for backward compatibility.
import tenant from '@/Framework/Tenant/State/Redux/reducer';
import containerReducer from '@/Framework/DI/containerReducer';

export default combineReducers({
  router: routerReducer,
  container: containerReducer,
  roadshow, // usage: DialInPopover (state.roadshow.dialIn)
  companies, // usage: Complete Investor Profile (Company Field)
  user, // usage: Complete Investor Profile (Title Field)
  tenant,
});
